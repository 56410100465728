import { OverwritingFareComponent } from './main/container/overwriting-fare/overwriting-fare/overwriting-fare.component';
import { ReportsComponent } from './main/container/reports/reports/reports.component';
import { RuleSettingsComponent } from './main/container/settings/rule-settings/rule-settings.component';
import { DispatchSettingsComponent } from './main/container/settings/dispatch-settings/dispatch-settings.component';
import { Liverides2Component } from './main/container/rides/liverides2/liverides2.component';
import { LoginGuard } from './guards/login-route.guard';
import { CanActivateRouteGuard } from './guards/can-activate-route.guard';
import { LoginComponent } from './main/login/login.component';
import { LiveMapComponent} from './main/container/live-map/component/live-map.component';
import { NgModule, ErrorHandler } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GlobalErrorHandler } from './guards/global-error-handler';

const routes: Routes = [

  {
    path:'',
    loadChildren:'./main/container/rides/rides.module#RidesModule',
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'login',
    component:LoginComponent,
    canActivate:[LoginGuard]
  },
  {
    path:'driver',
    loadChildren:'./main/container/driver/driver.module#DriverModule',
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'vehicle',
    loadChildren:'./main/container/vehicles/vehicle.module#VehicleModule',
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'rides',
    loadChildren:'./main/container/rides/rides.module#RidesModule',
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'livemap',
    loadChildren:'./main/container/live-map/live-map.module#LiveMapModule',
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'livemapbeta',
    loadChildren:'./main/container/live-map-beta/live-map-beta.module#LiveMapBetaModule',
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'driver-on-duty',
    loadChildren:'./main/container/duty/duty.module#DutyModule',
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'riders',
    loadChildren:'./main/container/rider/rider.module#RiderModule',
    canActivate:[CanActivateRouteGuard]

  },
  { path:'riders/:riderId',
    loadChildren:'./main/container/rider/rider.module#RiderModule',
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'reports',
    loadChildren:'./main/container/reports/reports.module#ReportsModule',
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'rider-new',
    loadChildren:'./main/container/rider-new/rider-new.module#RiderNewModule',
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'slots',
    loadChildren:'./main/container/slots/slots.module#SlotsModule',
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'customer-support',
    loadChildren:'./main/container/support/support.module#SupportModule',
    canActivate:[CanActivateRouteGuard]
  },

  // {
  //   path:'payments',
  //   loadChildren:'./main/container/payments/payments.module#PaymentsModule',
  //   canActivate:[CanActivateRouteGuard]

  // },
  {
    path:'payments',
    loadChildren:'./main/container/payments/payments.module#PaymentsModule',
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'setting',
    loadChildren:'./main/container/settings/settings.module#SettingsModule',
    canActivate:[CanActivateRouteGuard]

  },
  {
    path:'journey-details',
    loadChildren:'./main/container/journey-details/journey-details.module#JourneyDetailsModule',
    canActivate:[CanActivateRouteGuard]
  },
  {
    path:'overspeeding',
    loadChildren:'./main/container/overspeeding/overspeeding.module#OverspeedingModule',
    canActivate:[CanActivateRouteGuard]
  },
  {
    path: 'overwriting_fare',
    component: OverwritingFareComponent
  }
  // {
  //   path: 'overwriting_fare',
  //   loadChildren: '../app/main/container/overwriting-fare/overwriting-fare.module#OverwritingFareModule',
  //   canActivate: [CanActivateRouteGuard]
  // }
  // {
  //   path:'',
  //   component:LoginComponent,
  //   canActivate:[LoginGuard]

  // }
];
@NgModule({
    providers: [{provide: ErrorHandler, useClass: GlobalErrorHandler}],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
