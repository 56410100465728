//import { Urls } from './../../models/api.constants';
import { environment } from '../../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class Headers {
    static get headers() {
        return Headers.HEADERS;
    }
    static addHeaders(headers) {
        if (!Headers.HEADERS) {
            Headers.HEADERS = new HttpHeaders();
        }
        Headers.HEADERS = Headers.HEADERS.append(headers.key, headers.value);
    }
    static clearHeaders() {
        Headers.HEADERS = null;
    }
}
export class FormServiceService {
    constructor(http) {
        this.http = http;
    }
    requestThisUrl(url, token) {
        console.log(url);
        const URL = url.includes('https://') || url.includes('http://') ? url : environment.baseUrl + url;
        return this.http.get(URL).toPromise();
    }
}
FormServiceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormServiceService_Factory() { return new FormServiceService(i0.ɵɵinject(i1.HttpClient)); }, token: FormServiceService, providedIn: "root" });
