import * as tslib_1 from "tslib";
import { Observable } from 'rxjs';
import { COOKIE_URL, COOKIE_SUFFIX } from '../config/apiRouteConfig';
import Swal from 'sweetalert2';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
import * as i2 from "@angular/router";
import * as i3 from "../services/app.service";
export class CanActivateRouteGuard {
    constructor(cookieService, router, appService) {
        this.cookieService = cookieService;
        this.router = router;
        this.appService = appService;
    }
    canActivate(next, state) {
        const context = this;
        let rolesURL = {
            //"MENU_LIVE_RIDES_BETA" : "/rides/liverides",
            "MENU_LIVE_RIDES": "/rides/liverides/beta",
            "MENU_LIVE_RIDES_ALPHA": "/rides/liverides/alpha",
            "MENU_LIVE_MAP_CLUSTER": "/livemap",
            "MENU_LIVE_MAP": "/livemapbeta",
            "MENU_SCHEDULE_RIDES": "/rides/schedulerides",
            "MENU_PAST_RIDES": "/rides/pastrides",
            "MENU_DRIVERS_ON_DUTY": "/driver-on-duty",
            "MENU_VEHICLE": "/vehicle",
            "MENU_DRIVER": "/driver",
            "MENU_RIDER": "/riders",
            "MENU_RIDER_NEW": "/rider-new",
            "MENU_SLOTS": "/slots",
            "MENU_SETTING": "/setting",
            "MENU_REPORTS": "/reports",
            "MENU_PAYMENTS": "/payments",
            "MENU_CUSTOMER_SUPPORT": "/customer-support",
            "MENU_JOURNEY": "/journey-details",
            "MENU_OVERSPEEDING": "/overspeeding"
        };
        let userMenuRoles = {};
        return new Promise(function canActivate(resolve, reject) {
            context.appService.checkToken().subscribe((responseServer) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                if (responseServer.statusCode >= 300) {
                    context.appService.isValid = false;
                    //console.log("Can activate")
                    context.cookieService.delete('accessToken' + COOKIE_SUFFIX, '/', COOKIE_URL);
                    context.router.navigateByUrl('/login');
                    return reject(false);
                }
                else {
                    let ssoId = responseServer.response.sso_id;
                    let lastRole;
                    localStorage.setItem('ssoId', responseServer.response.sso_id);
                    // if(localStorage.getItem('isLastLogin') != 'true'){
                    yield context.appService.getAllZones(localStorage.getItem('ssoId')).subscribe((res) => {
                        if (res.status == 'SUCCESS') {
                            context.appService.zonesList = [];
                            Object.entries(res.response).forEach((ele) => {
                                let zone = {
                                    "label": ele[1],
                                    "value": ele[0]
                                };
                                context.appService.zonesList.push(zone);
                                localStorage.setItem("zonesList", JSON.stringify(context.appService.zonesList));
                                if (!context.appService.getCurrentServiceRegionId() || context.appService.getCurrentServiceRegionId()[0] == 'null' || context.appService.getCurrentServiceRegionId()[0] == 'n') {
                                    context.appService.selectedZones = [context.appService.zonesList[0].value];
                                    context.appService.setCurrentServiceRegionId([context.appService.zonesList[0].value]);
                                }
                            });
                            if (context.appService.zonesList.length == 0) {
                                context.appService.isValid = false;
                                context.cookieService.set("accessToken" + COOKIE_SUFFIX, null, 5000, "/", COOKIE_URL, null, "Lax");
                                localStorage.clear();
                                localStorage.setItem('isLastLogin', 'true');
                                Swal.fire({
                                    title: res['status'],
                                    icon: "error",
                                    text: "Zone permissions not found"
                                });
                                context.router.navigateByUrl('/login');
                                return reject(false);
                            }
                        }
                        else {
                            Swal.fire({
                                title: res['status'],
                                icon: "error",
                                text: "Zone permissions not found"
                            });
                            context.appService.isValid = false;
                            context.cookieService.delete('accessToken' + COOKIE_SUFFIX, '/', COOKIE_URL);
                            context.router.navigateByUrl('/login');
                            return reject(false);
                        }
                        //running routing code here
                        context.appService.getRolesForUser(ssoId).subscribe((res) => {
                            localStorage.setItem("userRoles", JSON.stringify(res.response));
                            for (let role of res.response) {
                                if (JSON.stringify(role).indexOf("MENU") !== -1 && rolesURL[role]) {
                                    userMenuRoles[rolesURL[role]] = role;
                                    lastRole = rolesURL[role];
                                }
                            }
                            if (lastRole == undefined) {
                                Swal.fire({
                                    title: "ERROR",
                                    icon: "error",
                                    text: "You don't have required permission.Please contact tech team"
                                });
                                context.appService.isValid = false;
                                context.cookieService.delete('accessToken' + COOKIE_SUFFIX, '/', COOKIE_URL);
                                context.router.navigateByUrl('/login');
                                return reject(false);
                            }
                            if (next['_routerState'].url.includes('/riders/') && res.response.includes('RIDER_REFUND_BLU_WALLET'))
                                return resolve(true);
                            if (!(userMenuRoles[next['_routerState'].url])) {
                                context.router.navigateByUrl(lastRole);
                                return reject(false);
                            }
                            return resolve(true);
                        });
                    });
                }
                // }
                // return resolve(true);
            }), (error) => { });
        });
    }
}
CanActivateRouteGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanActivateRouteGuard_Factory() { return new CanActivateRouteGuard(i0.ɵɵinject(i1.CookieService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AppService)); }, token: CanActivateRouteGuard, providedIn: "root" });
