
    <div
    [class]="config.className ? config.className : 'dynamicForm'"
      [formGroup]="group">
      <label *ngIf="config.label" [attr.for]="config.htmlId" style="font-size: 12px;">{{ config.label }}
      <span *ngIf="config.validations && config.validations.required" class="required_field">*</span>

      </label>
      <textarea
        [attr.id]="config.htmlId"
        [attr.placeholder]="config.placeholder"
        [formControlName]="config.name"
        style="width: 100%; font-size: 14px;"
        pInputTextarea
      ></textarea>
    </div>