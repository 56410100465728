<div class="container-fluid header-nav sticky-top">
  <!-- <div class="row justify-content-space-between"> -->
  <div id="mySidenav" class="sidenav" [ngStyle]="{'width':collapse==false?'300px':'0px'}">
    <!-- <a class="closebtn close" (click)="closeNav(null)">&times;</a> -->

    <div class="container" style="overflow: hidden;margin: 10px;">
      <div class="row" style="width: 500px;">
        <div class="col-6">
          <span class="tile">Dispatch</span>
          <div class="list-group" id="list-tab" role="tablist">
            <a *ngIf="checkPermission('MENU_LIVE_RIDES')" class="list-group-item list-group-item-action"
              id="list-ride-list" data-toggle="list" role="tab" aria-controls="liverides"
              [routerLink]="['/rides/liverides/beta']" routerLinkActive="active" (click)="closeNav(null)">Live Rides</a>
            <!-- <a *ngIf="checkPermission('MENU_LIVE_RIDES')" class="list-group-item list-group-item-action"
              id="list-home-list" data-toggle="list" role="tab" aria-controls="home" [routerLink]="['/rides/liverides']"
              [routerLink]="active" (click)="closeNav(null)"> Old Live Rides</a> -->
            <a *ngIf="checkPermission('MENU_PAST_RIDES')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile"
              [routerLink]="['/rides/pastrides']" routerLinkActive="active" (click)="closeNav(null)">Past Rides</a>
              <a *ngIf="checkPermission('MENU_LIVE_MAP_CLUSTER')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="livemap" [routerLink]="['/livemap']"
              routerLinkActive="active" (click)="closeNav('/livemap')">Live
              Map Cluster</a>
            <a *ngIf="checkPermission('MENU_LIVE_MAP')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="livemap" [routerLink]="['/livemapbeta']"
              routerLinkActive="active" (click)="closeNav('/livemapbeta')">Live Map</a>  
            <a *ngIf="checkPermission('MENU_DRIVERS_ON_DUTY')" class="list-group-item list-group-item-action"
              id="list-settings-list" data-toggle="list" role="tab" aria-controls="settings"
              [routerLink]="['/driver-on-duty']" routerLinkActive="active" (click)="closeNav('/driver-on-duty')">Drivers on Duty</a>
            <!-- <a class="list-group-item list-group-item-action" id="list-settings-list" data-toggle="list"  role="tab" aria-controls="settings" routerLink='/riders' (click)="closeNav(null)" >Riders</a> -->
            <a *ngIf="checkPermission('MENU_SCHEDULE_RIDES')" class="list-group-item list-group-item-action"
              id="list-settings-list" data-toggle="list" role="tab" aria-controls="settings"
              [routerLink]="['/rides/schedulerides']" routerLinkActive="active" (click)="closeNav(null)">Scheduled Rides</a>
          </div>
        </div>
      </div>
      <div class="row" style="width: 500px;">
        <div class="col-6">
          <br />
          <span class="tile">Manage</span>
          <div class="list-group" id="list-tab" role="tablist">
            <a *ngIf="checkPermission('MENU_VEHICLE')" class="list-group-item list-group-item-action "
              id="list-home-list" data-toggle="list" role="tab" aria-controls="home" routerLink='/vehicle'
              (click)="closeNav('/vehicle')">Vehicle</a>
            <a *ngIf="checkPermission('MENU_DRIVER')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile" routerLink='/driver'
              (click)="closeNav(null)">Driver</a>
            <a *ngIf="checkPermission('MENU_RIDER')" class="list-group-item list-group-item-action " id="list-home-list"
              data-toggle="list" role="tab" aria-controls="home" routerLink='/riders' (click)="closeNav(null)">Rider</a>
              <a *ngIf="checkPermission('MENU_RIDER_NEW')" class="list-group-item list-group-item-action " id="list-home-list"
              data-toggle="list" role="tab" aria-controls="home" routerLink='/rider-new' (click)="closeNav('/rider-new')">Rider 2.0</a>
            <!-- <a *ngIf="checkPermission('MENU_CUSTOMER_SUPPORT')" class="list-group-item list-group-item-action " id="list-home-list"
              data-toggle="list" role="tab" aria-controls="home" routerLink='/customer-support' (click)="closeNav(null)">Customer Support</a> -->
            <a *ngIf="checkPermission('MENU_PAYMENTS')" class="list-group-item list-group-item-action "
              id="list-home-list" data-toggle="list" role="tab" aria-controls="home" routerLink='/payments'
              (click)="closeNav(null)">Payments</a>
            <a *ngIf="checkPermission('MENU_REPORTS')" class="list-group-item list-group-item-action "
              id="list-home-list" data-toggle="list" role="tab" aria-controls="home" routerLink='/reports'
              (click)="closeNav('/reports')">Reports</a>
            <a *ngIf="checkPermission('MENU_JOURNEY')" class="list-group-item list-group-item-action"
              id="journey-details" data-toggle="list" role="tab" aria-controls="journery-details"
              routerLink='/journey-details' (click)="closeNav('/journey-details')">Driver-Vehicle History</a>
            <a *ngIf="checkPermission('MENU_OVERSPEEDING')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile" routerLink='/overspeeding'
              (click)="closeNav(null)">Overspeeding</a>
              <a *ngIf="checkPermission('MENU_OVERWRITING_FARE')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile"
              routerLink='/overwriting_fare' (click)="closeNav(null)">Custom Pricing</a>
            <a *ngIf="checkPermission('MENU_SETTING')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile" routerLink='/setting'
              (click)="closeNav('/setting')">Settings</a>
              <a *ngIf="checkPermission('MENU_SLOTS')" class="list-group-item list-group-item-action"
              id="list-profile-list" data-toggle="list" role="tab" aria-controls="profile" routerLink='/slots'
              (click)="closeNav('/slots')">Slots</a>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class='d-flex justify-content-between'>
    <div class='d-flex justify-content-around'>

      <span style="font-size:20px;cursor:pointer;color: white;" class="col-xs-4 ml-3 mb-1" (click)="openNav()"
        *ngIf='appService.isValid'> &#9776;&nbsp; Menu</span>
      <!-- <span style="font-size:20px;cursor:pointer" class="col-xs-4 ml-3"(click)="openNav()"
           *ngIf='!appService.isValid'> </span> -->
      <div class='ml-3'>
        <img src="../../../../assets/logo2.png" alt="" width='70'>
      </div>

      <div>
        <div *ngIf='appService.isValid' class="form-group pl-4" style="margin: auto;">
          <!-- multiselect with multiple selection -->
          <p-multiSelect appendTo="body" [options]="appService.zonesList" [(ngModel)]="appService.selectedZones"
            *ngIf="isEnableMultiSelect" (onChange)="onServiceRegionChange($event.value)" [style]="{'width':'100%'}"
            [panelStyle]="{'width': '170px'}"></p-multiSelect>

          <select (change)="onServiceRegionChange($event.target.value)" *ngIf="!isEnableMultiSelect"  [(ngModel)]="appService.selectedZone" name="" id="" class='form-control'>
            <option *ngFor="let zone of appService.zonesList" [value]="zone.value">
              {{zone.label}}
            </option>
        </select>
        </div>
      </div>

    </div>

    <div style="margin-top: auto; margin-bottom: auto;">
      <span *ngIf="appService.isValid && appService.name" style="padding-right: 20px;font-size: 16px;"> Hello,
        {{appService.name}} &nbsp; |</span>
      <a class='logout' *ngIf='appService.isValid' (click)='logout()' (click)='closeNav(null)'>
        <i class="fa fa-sign-out" aria-hidden="true" style="padding-right: 2px;"></i> Logout</a>
    </div>
  </div>




</div>
<p-dialog header="Create ticket" [(visible)]="displayCreateTicket" [draggable]="false">
  <app-create-ticket *ngIf="displayCreateTicket"></app-create-ticket>
</p-dialog>
<!-- 
<button *ngIf='appService.isValid' class="btn btn-primary widget" (click)="openCreateTicketDailog()"><i
    class="fa fa-ticket"></i></button> -->

<p-toast>
  <ng-template let-message pTemplate="message">
    <div class="stack">
      <p class="head">
        <ng-container>
          <i class="pi pi-check" *ngIf="message.severity == 'success' "></i>
          <i class="pi pi-info-circle" *ngIf="message.severity == 'info' "></i>
        </ng-container>
        &nbsp;
        {{ message.summary }}
      </p>
      <p *ngIf="message.detail" class="detail">
        {{ message.detail }}
      </p>
      <ng-container *ngIf="message.data">
        <p *ngFor="let detail of message.data" class="detail">
          {{ detail }}
        </p>
      </ng-container>
    </div>
  </ng-template>
</p-toast>