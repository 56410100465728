<div class="heirarchy">
    <div *ngIf="currentlyActiveForm" class="container" style="padding: 10px;">
      <div class="background">
        <div class="steps">
          <p-steps
            [model]="items"
            [readonly]="false"
            [activeIndex]="activeIndex"
            (activeIndexChange)="activeIndexChangeHandler($event)"
          ></p-steps>
        </div>
        <div class="renderedForm">
          <!-- <p style="padding-left:20%;">{{ currentlyActiveForm.name }}</p> -->
          <app-form-renderer
            [formConfig]="currentlyActiveForm"
            (formSubmitHandler)="submitCurrentForm($event)"
          >
          </app-form-renderer>
          
          <button
            [disabled]="activeIndex <= 0"
            (click)="activeIndexChangeHandler(activeIndex - 1)"
            label="Back"
            pButton
            class="btn btn-primary backButton"  
          > Back</button>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoadComplete" style="padding-left: 40%; padding-top: 30%;"> 
      <p-progressSpinner></p-progressSpinner>
    </div>
  </div>