import { API_NON_BUSINESS_SOURCE, API_URL_SEND_OTP_RIDER, API_URL_BLOCK_RIDER, API_URL_BLOCK_RIDER_IMEI, API_URL_LIST_RIDER, API_URL_ADD_RIDER_PROMO, API_URL_RIDER_DEVICE_INFORMATION, API_URL_RIDER_FEEDBACK, API_URL_PASTRIDE_CSV_DOWNLOAD, API_URL_REPORT_RIDER_FEEDBACK_CSV_DOWNLOAD, API_URL_RIDER_DETAILS, API_URL_ALL_PACKAGES, API_RIDER_BLU_WALLET_REFUND, API_RIDE_DETAILS, API_RIDER_DETAILS, API_UPDATE_AGENT_DATA, API_URL_CALL_DETAILS, API_URL_USER_DETAILS, API_URL_RIDE_ETA, API_FETCH_ALL_USER, API_URL_DELETED_USERS_CSV_DOWNLOAD, API_URL_REJOINED_USERS_CSV_DOWNLOAD, API_URL_GET_PRIVE_DETAILS, API_URL_RIDER_INFORMATION } from './../../config/apiRouteConfig/index';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RiderService {
    constructor(_http) {
        this._http = _http;
    }
    getUserDetailsBySsoId(ssoId) {
        return this._http.get(API_URL_USER_DETAILS + "/" + ssoId + "/fetch");
    }
    getRidersList(field, parameter) {
        let url = API_URL_LIST_RIDER;
        if (field)
            url += "?" + field + "=" + parameter;
        return this._http.get(url);
    }
    blockThisRider(riderId, isBlocked, comment) {
        return this._http.post(`${API_URL_BLOCK_RIDER}`, { riderId, blockUser: isBlocked, comment: comment });
    }
    blockThisRiderImei(riderId, isImeiBlocked) {
        return this._http.post(`${API_URL_BLOCK_RIDER_IMEI}`, { riderId, blockImeiNumber: isImeiBlocked });
    }
    setOtpToThisRider(riderId) {
        return this._http.get(`${API_URL_SEND_OTP_RIDER}/${riderId}`);
    }
    getAllNonBusinessSource() {
        return this._http.get(`${API_NON_BUSINESS_SOURCE}`);
    }
    setRiderType(riderId, code) {
        return this._http.post(`${API_URL_ADD_RIDER_PROMO}`, { riderId, promoCode: code, source: "DISPATCH" });
    }
    getDeviceInformation(riderId) {
        return this._http.get(`${API_URL_RIDER_DEVICE_INFORMATION}/${riderId}`);
    }
    getRiderInformation(riderId) {
        return this._http.get(`${API_URL_RIDER_INFORMATION}/${riderId}`);
    }
    getRiderFeedback(startDate, endDate) {
        return this._http.get(`${API_URL_RIDER_FEEDBACK}` + '?startTime=' + startDate + '&endTime=' + endDate);
    }
    getmockRiderFeedback() {
        return this._http.get(`https://run.mocky.io/v3/cb30d721-f00d-415a-a2fb-09157d5bb6cb`);
    }
    downloadCSVRidersFeedback(startDate, endDate) {
        return this._http.get(API_URL_REPORT_RIDER_FEEDBACK_CSV_DOWNLOAD + '?startTime=' + startDate + '&endTime=' + endDate, { responseType: 'blob' });
    }
    downloadCSVPastRide(startDate, endDate) {
        return this._http.get(API_URL_PASTRIDE_CSV_DOWNLOAD + '?startTime=' + startDate + '&endTime=' + endDate, { responseType: 'blob' });
    }
    initiateRiderBluWalletRefund(walletRefundData) {
        return this._http.post(`${API_RIDER_BLU_WALLET_REFUND}`, walletRefundData);
    }
    getRideDetails(riderId) {
        return this._http.get(`${API_RIDE_DETAILS}` + '?riderId=' + riderId);
    }
    getRidersData(filterType) {
        return this._http.get(`${API_RIDER_DETAILS}` + "?filterType=" + filterType);
    }
    updateAgentData(body) {
        return this._http.put(`${API_UPDATE_AGENT_DATA}`, body);
    }
    getRiderData(phoneNo) {
        return this._http.get(API_URL_RIDER_DETAILS + "?phone=" + phoneNo);
    }
    getAllRentalPackages() {
        return this._http.get(API_URL_ALL_PACKAGES);
    }
    getRiderDataByRiderId(riderId) {
        return this._http.get(API_URL_RIDER_DETAILS + "/" + riderId);
    }
    getCallLogData(start, end) {
        return this._http.get(API_URL_CALL_DETAILS + "?startTime=" + start + "&endTime=" + end);
    }
    getUpdatedEtaForLiveRide(ride) {
        return this._http.post(API_URL_RIDE_ETA, ride);
    }
    getAllUsers() {
        return this._http.get(API_FETCH_ALL_USER);
    }
    downloadCSVDeletedUsers(startDate, endDate) {
        return this._http.get(API_URL_DELETED_USERS_CSV_DOWNLOAD + '?startTime=' + startDate + '&endTime=' + endDate, { responseType: 'blob' });
    }
    downloadCSVRejoinedUsers(startDate, endDate) {
        return this._http.get(API_URL_REJOINED_USERS_CSV_DOWNLOAD + '?startTime=' + startDate + '&endTime=' + endDate, { responseType: 'blob' });
    }
    getPriveDetails(riderId) {
        return this._http.get(API_URL_GET_PRIVE_DETAILS + riderId);
    }
}
RiderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RiderService_Factory() { return new RiderService(i0.ɵɵinject(i1.HttpClient)); }, token: RiderService, providedIn: "root" });
