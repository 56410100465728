import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment'
import { Headers} from '../../models/api.headers';


@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private _http: HttpClient, private cookieService: CookieService) { }
  getFormById(formId): Observable<any> {
    let url;
    this.cookieService.get('selectedProject')=='techSupportTMS' ? url ='/api/v1/form/get-active-form-by-id?formId=' : url ='/api/v1/form/get-active-form-by-id?formId=';
    return this._http.get<{}>(environment.ticket + url + formId, {headers: Headers.headers} );
   }
   createTicket(ticketFormData): Observable<{}> {
    let url
    this.cookieService.get('selectedProject')=='techSupportTMS' ? url='/api/v1/tickets/details/create' : url='/api/v1/tickets/details/create' ;
    return this._http.post(environment.ticket + url, ticketFormData);
  }
  fetchCurrentlyActiveForm(project, category, name, isRoot?: boolean): Observable<any> {
    const query: any = {project, category, name};
    if (typeof isRoot === 'boolean') {
      query.isRoot = isRoot;
    }
   // project=='techSupportTMS' ? query['isUserPermissionCheck']=true : null;
    return this._http.post(environment.ticket + '/api/v1/form/get-active-form', query);
  }
}
