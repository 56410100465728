import { environment } from '../../../environments/environment';
import { Headers } from '../../models/api.headers';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "ngx-cookie-service";
export class TicketService {
    constructor(_http, cookieService) {
        this._http = _http;
        this.cookieService = cookieService;
    }
    getFormById(formId) {
        let url;
        this.cookieService.get('selectedProject') == 'techSupportTMS' ? url = '/api/v1/form/get-active-form-by-id?formId=' : url = '/api/v1/form/get-active-form-by-id?formId=';
        return this._http.get(environment.ticket + url + formId, { headers: Headers.headers });
    }
    createTicket(ticketFormData) {
        let url;
        this.cookieService.get('selectedProject') == 'techSupportTMS' ? url = '/api/v1/tickets/details/create' : url = '/api/v1/tickets/details/create';
        return this._http.post(environment.ticket + url, ticketFormData);
    }
    fetchCurrentlyActiveForm(project, category, name, isRoot) {
        const query = { project, category, name };
        if (typeof isRoot === 'boolean') {
            query.isRoot = isRoot;
        }
        // project=='techSupportTMS' ? query['isUserPermissionCheck']=true : null;
        return this._http.post(environment.ticket + '/api/v1/form/get-active-form', query);
    }
}
TicketService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TicketService_Factory() { return new TicketService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.CookieService)); }, token: TicketService, providedIn: "root" });
