
    <div
    [class]="config.className ? config.className : 'dynamicForm'"
      [formGroup]="group">
      <div *ngIf=" config.htmlType === 'checkbox' " class=" Input_Group_Container  row ">
        <label *ngIf="config.label" class="checkbox">{{config.label}}
        <span *ngIf="config.validations && config.validations.required" class="required_field">*</span>

        </label>
        <ng-container *ngFor="let checkbox of config.options" >
          <p-checkbox
            [name]="config.groupName ? config.groupName : config.name"
            [formControl]="group.controls[config.name]"
            [label]="checkbox.label"
            [value]="checkbox.value"
            [inputId]="checkbox._id"
            [styleClass]=" checkbox.className">
          </p-checkbox>
        </ng-container>
      </div>
      <div *ngIf=" config.htmlType === 'radio' " class="Input_Group_Container row">
        <label *ngIf="config.label" class="radio-button">{{config.label}}
        <span *ngIf="config.validations && config.validations.required" class="required_field">*</span>

        </label>
        <p-radioButton
          *ngFor="let radio of config.options"
          [name]="config.groupName ? config.groupName : config.name"
          [value]="radio.value"
          [formControlName]="config.name"
          [label]="radio.label"
          [styleClass]=" radio.className">
        </p-radioButton>
      </div>
    </div>
  