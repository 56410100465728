/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./audio-player.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./audio-player.component";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
var styles_AudioPlayerComponent = [i0.styles];
var RenderType_AudioPlayerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AudioPlayerComponent, data: {} });
export { RenderType_AudioPlayerComponent as RenderType_AudioPlayerComponent };
export function View_AudioPlayerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title"], ["id", "modal-basic-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Audio Player"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "audio", [["controls", "controls"], ["style", "margin-left: 70px;margin-top: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "source", [["type", "audio/ogg"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.callHistory, ""); _ck(_v, 8, 0, currVal_0); }); }
export function View_AudioPlayerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-audio-player", [], null, null, null, View_AudioPlayerComponent_0, RenderType_AudioPlayerComponent)), i1.ɵdid(1, 114688, null, 0, i2.AudioPlayerComponent, [i3.NgbActiveModal], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AudioPlayerComponentNgFactory = i1.ɵccf("app-audio-player", i2.AudioPlayerComponent, View_AudioPlayerComponent_Host_0, { callHistory: "callHistory" }, {}, []);
export { AudioPlayerComponentNgFactory as AudioPlayerComponentNgFactory };
