import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css']
})
export class AudioPlayerComponent implements OnInit {

  @Input()
  callHistory: any;
  constructor(public ngbActiveModal:NgbActiveModal) { }

  ngOnInit() {
  }
  close(){
    this.ngbActiveModal.close();
  }
}
