<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Audio Player</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body"> 
    <audio style="margin-left: 70px;margin-top: 20px;" controls="controls">
        <source src="{{callHistory}}" type="audio/ogg" />
    </audio>
  </div>
<!-- <app-modal modalHeader='Audio Player'>
    <div style="width:100%; height:50%;overflow-y: hidden;overflow-x: hidden;">
        <audio style="width: 235px;padding: 10px;" controls="controls">
            <source src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-3.mp3" type="audio/ogg" />
        </audio>


    </div>

</app-modal> -->