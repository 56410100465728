import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './component/header.component';

import { CreateTicketComponent } from '.././ticket/create-ticket/create-ticket.component';
//import { FormComponent } from './../ticket/form/form.component';

import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { StepsModule } from 'primeng/steps';
import { FormModule } from '../ticket/form/form.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {  MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

const route = [
  { path: '**', redirectTo: 'rides' }
];

@NgModule({
  declarations: [HeaderComponent,CreateTicketComponent],
  imports: [
    CommonModule,
    RouterModule.forRoot(route),
    DialogModule,
    FormModule,
    FormsModule,
    MultiSelectModule,
    StepsModule,
    ProgressSpinnerModule,
    ToastModule
  ],providers: [MessageService],
  exports:[HeaderComponent]
})
export class HeaderModule { }
