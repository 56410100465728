import { Validators } from '@angular/forms';
import { FieldConfig } from './dynamic-forms/model/field-config.interface';

const supportedInputTypes = {
  text: true,
  email: true,
  password: true,
  number: true
};
function constructInput(config) {
  const {
    htmlType = 'text',
    label,
    _id: htmlId,
    name,
    placeholder,
    value,
    validations,
    options,
    className,
    elementType = 'endPoint',
    disabled = false,
  } = config;
  if (!supportedInputTypes[htmlType]) {
    return null;
  }
  const elConfig: FieldConfig = {
    htmlType,
    type: 'input',
    label,
    htmlId,
    name,
    placeholder,
    value,
    validation: [],
    options,
    className,
    elementType,
    disabled,
    validations,
  };

  if (htmlType === 'email') {
    elConfig.validation.push(Validators.email);
  }
  if (validations) {
    if (validations.required) {
      elConfig.validation.push(Validators.required);
    }
    if (validations.maxLength && typeof validations.maxLength == 'number' && !Number.isNaN(validations.maxLength)) {
      elConfig.validation.push(Validators.maxLength(validations.maxLength));
    }
    if (validations.minLength && typeof validations.minLength == 'number' && !Number.isNaN(validations.minLength)) {
      elConfig.validation.push(Validators.minLength(validations.minLength));
    }
    if (htmlType == 'number' && validations.max && typeof validations.max === 'number' && !Number.isNaN(validations.max)) {
      elConfig.validation.push(Validators.max(validations.max));
    }
    if (htmlType == 'number' && validations.min && typeof validations.min === 'number' && !Number.isNaN(validations.min)) {
      elConfig.validation.push(Validators.min(validations.min));
    }
  }
  return elConfig;
}
const supportedInputGroupTypes = {
  checkbox: true,
  radio: true,
};
function constructInputGroup(config) {
  const {
    htmlType,
    html: type,
    _id: htmlId,
    name,
    label,
    options,
    validations,
    className,
    groupName = name,
    elementType = 'endPoint',
    value,
  } = config;
  if (!supportedInputGroupTypes[htmlType]) {
    return null;
  }
  const elConfig: FieldConfig = {
    type,
    htmlType,
    htmlId,
    name,
    label,
    options,
    validation: [],
    className,
    groupName,
    elementType,
    value,
    validations,
  };
  if (validations) {
    if (validations.required) {
      if (htmlType === 'checkbox') {
        elConfig.validation.push(Validators.required); // field required not true value requriedTrue

      } else {
        elConfig.validation.push(Validators.required);
      }
    }
  }
  return elConfig;
}
function constructTextarea(config) {
  const {
    validations,
    _id: htmlId,
    name,
    label,
    className,
    elementType = 'endPoint',
    value
  } = config;

  const elConfig: FieldConfig = {
    type: 'textarea',
    name,
    htmlType: 'textarea',
    htmlId,
    validation: [],
    label,
    className,
    elementType,
    value,
    validations,
  };

  if (validations) {
    if (validations.required) {
      elConfig.validation.push(Validators.required);
    }
    if (validations.maxLength && typeof validations.maxLength == 'number' && !Number.isNaN(validations.maxLength)) {
      elConfig.validation.push(Validators.maxLength(validations.maxLength));
    }
    if (validations.minLength && typeof validations.minLength == 'number' && !Number.isNaN(validations.minLength)) {
      elConfig.validation.push(Validators.minLength(validations.minLength));
    }
  }

  return elConfig;
}
function constructSelect(config) {
  const {
    placeholder,
    options,
    label,
    _id: htmlId,
    name,
    value,
    filter,
    validations,
    multiple = false,
    className,
    elementType,
    optionLabel = `Choose ${label}`,
    styleClass,
    panelStyleClass,
    filterPlaceholder,
    disabled = false,
  } = config;

  const elConfig: FieldConfig = {
    placeholder,
    options,
    type : 'select',
    name,
    label,
    htmlId,
    filter,
    validation: [],
    multiple,
    className,
    elementType,
    value,
    optionLabel,
    disabled,
    styleClass,
    panelStyleClass,
    filterPlaceholder,
    validations,
  };
  if (validations) {
    if (validations.required) {
      elConfig.validation.push(Validators.required);
    }
  }
  return elConfig;
}
function constructInputFile(config) {
  const {
    label,
    _id: htmlId,
    name,
    placeholder,
    accept,
    validations,
    className,
    elementType = 'endPoint',
    multiple,
    disabled,
    value,
  } = config;
  console.log(accept);
  const elConfig: any = {
    type: 'inputFile',
    label,
    htmlId,
    name,
    placeholder,
    htmlType: 'file',
    accept: accept && Array.isArray(accept) ? accept.join(',') : ['audio/*', 'video/*', 'image/*', 'application/pdf'].join(','),
    validation: [],
    className,
    elementType,
    multiple,
    disabled,
    value,
    validations,
  };

  if (validations) {
    if ('required' in config) {
      elConfig.validation.push(Validators.required);
    }
  }
  return elConfig;
}
function constructDateTime(config) {
  const {
    label,
    _id: htmlId,
    name,
    placeholder,
    validations,
    className,
    elementType = 'endPoint',
    multiple,
    disabled,
    value,
    htmlType = 'datetime',
  } = config;

  const elConfig: any = {
    type: 'dateTime',
    label,
    htmlId,
    name,
    placeholder,
    htmlType,
    validation: [],
    className,
    elementType,
    multiple,
    disabled,
    value,
    validations,
  };

  if (validations) {
    if ('required' in config) {
      elConfig.validation.push(Validators.required);
    }
  }
  return elConfig;
}
export function converter(config) {

  return config.map(function formBuilder(elementConfig) {

    switch (elementConfig.html) {
      case 'input':
        return constructInput(elementConfig);
      case 'textarea':
        return constructTextarea(elementConfig);
      case 'button':
        elementConfig.type = elementConfig.html;
        return elementConfig;
      case 'select':
        return constructSelect(elementConfig);
      case 'inputGroup':
        return constructInputGroup(elementConfig);
      case 'inputFile':
        return constructInputFile(elementConfig);
      case 'dateTime':
        return constructDateTime(elementConfig);
    }
  })
  .filter(Boolean);
}
