
    <ng-container>
    <dynamic-form
    [config]="formElementsConfig"
    #form="dynamicForm"
    (submit)="submit($event)"
    [areWeUpForUpdate]="areWeUpForUpdate"
    (updateElementHandler)="updateFieldElementHandler($event)"
    (deleteElementHandler)="deleteFieldElementHandler($event)"
    (dropElementHandler)="dropFieldElementHandler($event)"
    >
    </dynamic-form>
    <ng-container *ngIf="areWeUpForUpdate">
      <button (click)="addFieldElementHandler($event)">Add elements</button>
    </ng-container>
    </ng-container>
  