
    <div style="position: relative;"> 
    <p style="color: white;font-size: 14px;padding-top: 10px; padding-bottom: 8px;">Upload File</p>
    <!-- <label [attr.for]="inputId"> {{label}} </label> <br/>
      <input
      class="file-input" 
        type="file"
        [attr.id]="inputId"
        [attr.accept]="accept"
        [attr.multiple]="multiple"
      />-->
      <span class="btn btn-primary btn-file" style="background-color: #292929 !important; border-color: #292929 !important;">
     
    <input 
    type="file"
    [attr.id]="inputId"
    [attr.accept]="accept"
    [attr.multiple]="multiple">
     </span>
    </div>
  