import { COOKIE_SUFFIX } from '../config/apiRouteConfig';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
import * as i2 from "@angular/router";
import * as i3 from "../services/app.service";
import * as i4 from "@angular/common";
export class LoginGuard {
    constructor(cookieService, router, appService, location) {
        this.cookieService = cookieService;
        this.router = router;
        this.appService = appService;
        this.location = location;
    }
    canActivate(next, state) {
        let token = this.cookieService.get('accessToken' + COOKIE_SUFFIX);
        if (!token || !this.appService.isValid) {
            this.appService.isValid = false;
            return true;
        }
    }
}
LoginGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginGuard_Factory() { return new LoginGuard(i0.ɵɵinject(i1.CookieService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.AppService), i0.ɵɵinject(i4.Location)); }, token: LoginGuard, providedIn: "root" });
