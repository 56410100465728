import { ButtonModule } from 'primeng/button';
import { SettingService } from 'src/app/services/settings/setting.service';
import { TableModule } from 'primeng/table';
import { OverwritingFareComponent } from './main/container/overwriting-fare/overwriting-fare/overwriting-fare.component';
import { RiderService } from './services/rider/rider.service';

import { FormsModule } from '@angular/forms';
import { AppService } from './services/app.service';
import { AppInterceptor } from './app.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderModule } from './main/header/header.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { LoginComponent } from './main/login/login.component';
import { CookieService } from 'ngx-cookie-service';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';


import { DutyService } from './services/duty/duty.service';
import { GlobalErrorHandler } from './guards/global-error-handler';
import { DriverService } from './services/driver/driver.service';
import { VehicleService } from './services/vehicle/vehicle.service';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
//import { CreateTicketComponent } from './main/ticket/create-ticket/create-ticket.component';
//import { FormComponent } from './main/ticket/form/form.component';

import { NgxSpinnerModule } from "ngx-spinner";
import { DialogModule } from 'primeng/dialog';
import { StepsModule } from 'primeng/steps';
import { FormModule } from '../app/main/ticket/form/form.module';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageService } from 'primeng/api';
// import { DispatchSettingsComponent } from './main/container/setting/dispatch-settings/dispatch-settings.component';
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { ILLUMINAIRE_URL } from "../app/config/apiRouteConfig/index";
import { AudioPlayerComponent } from '../app/main/container/rides/audio-player/audio-player.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { HubService } from './services/hub/hub.service';
import { MultiSelectModule } from 'primeng/multiselect';

const socketConfig: SocketIoConfig = { url: ILLUMINAIRE_URL };
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("393352348407-cctavkfvbphf4cuc3957n3nnv7r94uun.apps.googleusercontent.com")
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AudioPlayerComponent,
    OverwritingFareComponent
  ],

  imports: [
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyD2M3bZsTzipyR5h9WsG4Lox_3bsrzU7Es'
    //   /* apiKey is required, unless you are a
    //   premium customer, in which case you can
    //   use clientId
    //   */
    // }),
    // AgmSnazzyInfoWindowModule,
    BrowserModule,
    AppRoutingModule,
    HeaderModule,
    BrowserAnimationsModule,
    HttpClientModule,
    Ng4LoadingSpinnerModule.forRoot(),
    SocketIoModule.forRoot(socketConfig),
    NgxSpinnerModule,
    FormsModule,
    AngularFontAwesomeModule,
    DialogModule,
    StepsModule,
    FormModule,
    ProgressSpinnerModule,
    SocialLoginModule,
    TableModule, ButtonModule, InputSwitchModule,MultiSelectModule,
    AgmCoreModule.forRoot(
      {
        apiKey: 'AIzaSyCMmbclH3d8bR8FnQdU5S4NIY0AknFzZSI',
        libraries: ['places']
      }
    ),
    AgmDirectionModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,

    },
    GoogleMapsAPIWrapper,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    CookieService,
    AppService,
    DutyService,
    HubService,
    RiderService, DriverService, VehicleService, MessageService, SettingService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [AudioPlayerComponent]
})

export class AppModule { }
