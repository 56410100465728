import {
  API_URL_GET_ALL_CONFIGURE_RULES, API_URL_GET_RULES_ID_WISE,
  API_URL_INSERT_CONFIGURATION_RULE, API_URL_UPDATE_CONFIGURATION_RULE,
  API_UPLOAD_KML_FILE,API_GET_SOURCE,
  API_URL_CREATE_LEASE_CONFIG,API_URL_GET_ALL_LEASE_CONFIGURATION, API_URL_UPDATE_LEASE_CONFIG, API_URL_DELETE_LEASE_CONFIG, API_URL_GET_PROMO_CONFIG, API_URL_CREATE_PROMO_CONFIG, API_URL_ACTIVATE_PROMO_CONFIG, API_URL_UPDATE_PROMO_CONFIG, API_GET_OVERWRITING_FARE, API_UPLOAD_FLAGS_CSV
} from '../../config/apiRouteConfig/index';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const HttpUploadOptions = {
  headers: new HttpHeaders({ "Content-Type": "multipart/form-data" })
}
@Injectable()
export class SettingService {
  callListApi= true;

  constructor(private _http: HttpClient) { }

  getAllConfigureRules() :Observable<any>{
    return this._http
    .get(API_URL_GET_ALL_CONFIGURE_RULES);
  }
  getRulesIdWise(ruleName) :Observable<any>{
    return this._http.get(`${API_URL_GET_RULES_ID_WISE}rule_name=${ruleName}`)
  }
  // insertConfigureRule() :Observable<any>{
  //   return this._http.post(API_URL_INSERT_CONFIGURATION_RULE);
  // }
  insertConfigureRule(data){
    return this._http.post(API_URL_INSERT_CONFIGURATION_RULE, data);
  }
  updateConfigureRule(data){
    return this._http.post(API_URL_UPDATE_CONFIGURATION_RULE, data);
  }

  uploadFile(file): Observable<any>{
    return this._http.post(API_UPLOAD_KML_FILE, file);
  }

  generateNewLeaseConfiguration(data){
    return this._http.post(API_URL_CREATE_LEASE_CONFIG, data)
  }

  updateLeaseConfiguration(data){
    return this._http.post(API_URL_UPDATE_LEASE_CONFIG, data)
  }

  deleteLeaseConfigScheduler(leaseConfigId){
    return this._http.delete(`${API_URL_DELETE_LEASE_CONFIG}leaseConfigId=${leaseConfigId}`)
  }
  getAllLeaseConfihuration(){
    return this._http.get(API_URL_GET_ALL_LEASE_CONFIGURATION);
  }
  getAllPromoConfiguration(){
    return this._http.get(API_URL_GET_PROMO_CONFIG);
  } 
  createPromoScreen(data){
    return this._http.post(API_URL_CREATE_PROMO_CONFIG,data)
  }
  activatePromo(id){
    return this._http.post(API_URL_ACTIVATE_PROMO_CONFIG+"?id="+id,id)
  }
  updatePromo(id,promoDto){
    return this._http.post(API_URL_UPDATE_PROMO_CONFIG+"?id="+id,promoDto)
  }

  uploadRiderFlagsCSVFile(selectedFlagType, fileToUpload){
    return this._http.post(API_UPLOAD_FLAGS_CSV+"/"+selectedFlagType, fileToUpload)
  }

  


  //overwriting fare API's

  getOverwritingFares(){
    return this._http.get(API_GET_OVERWRITING_FARE)
  }
  getOverwritingFareById(id){
    return this._http.get(API_GET_OVERWRITING_FARE+"/"+id)
  }
  createOverWritingFare(body){
    return this._http.post(API_GET_OVERWRITING_FARE,body)
  }
  updateOverWrtingFare(id,body){
    return this._http.put(API_GET_OVERWRITING_FARE+"/"+id,body)
  }
  deleteOverWritingFare(id){
    return this._http.delete(API_GET_OVERWRITING_FARE+"/"+id)
  }
  updateFair(id,body){
    return this._http.put(API_GET_OVERWRITING_FARE+"/"+id,body)
  }

  getSources(){
    return this._http.get(API_GET_SOURCE);
  }
}
