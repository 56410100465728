
    <div
    [class]="config.className ? config.className : 'dynamicForm'"
      [formGroup]="group">
      <label *ngIf="config.label" for="config.htmlId">{{ config.label }}
      <span *ngIf="config.validations && config.validations.required" class="required_field">*</span>

      </label>
      <ng-container *ngIf="config.multiple">
        <p-multiSelect
          appendTo="body"
          [options]="config.options"
          [formControlName]="config.name"
          [defaultLabel]="config.optionLabel"
          filter="true"
          filterBy="label, value"
          [styleClass]=" config.styleClass ? config.styleClass : 'dynamicForm singleSelect'"
          [panelStyleClass]=" config.styleClass ? config.styleClass : ''">
        </p-multiSelect>
      </ng-container>
      <ng-container *ngIf="!config.multiple">
        <p-dropdown
          appendTo="body"
          [options]="config.options"
          [formControlName]="config.name"
          filter="true"
          filterBy="label, value"
          [placeholder]="config.placeholder ? config.placeholder : config.label"
          [styleClass]=" config.styleClass ? config.styleClass : ''"
          [panelStyleClass]=" config.styleClass ? config.styleClass : 'dynamicForm multiSelect'">
        </p-dropdown>
      </ng-container>
    </div>