/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./create-ticket.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/primeng/steps/primeng-steps.ngfactory";
import * as i3 from "primeng/steps";
import * as i4 from "../form/form.component.ngfactory";
import * as i5 from "../form/form.component";
import * as i6 from "../form/form-service.service";
import * as i7 from "../../../../../node_modules/primeng/progressspinner/primeng-progressspinner.ngfactory";
import * as i8 from "primeng/progressspinner";
import * as i9 from "@angular/common";
import * as i10 from "./create-ticket.component";
import * as i11 from "ngx-cookie-service";
import * as i12 from "@angular/router";
import * as i13 from "primeng/api";
import * as i14 from "../../../services/ticket/ticket.service";
var styles_CreateTicketComponent = [i0.styles];
var RenderType_CreateTicketComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateTicketComponent, data: {} });
export { RenderType_CreateTicketComponent as RenderType_CreateTicketComponent };
function View_CreateTicketComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "container"], ["style", "padding: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "steps"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p-steps", [], null, [[null, "activeIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("activeIndexChange" === en)) {
        var pd_0 = (_co.activeIndexChangeHandler($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_Steps_0, i2.RenderType_Steps)), i1.ɵdid(4, 49152, null, 0, i3.Steps, [], { activeIndex: [0, "activeIndex"], model: [1, "model"], readonly: [2, "readonly"] }, { activeIndexChange: "activeIndexChange" }), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "renderedForm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-form-renderer", [], null, [[null, "formSubmitHandler"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("formSubmitHandler" === en)) {
        var pd_0 = (_co.submitCurrentForm($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FormRendererComponent_0, i4.RenderType_FormRendererComponent)), i1.ɵdid(7, 638976, null, 0, i5.FormRendererComponent, [i6.FormServiceService], { formConfig: [0, "formConfig"] }, { formSubmitHandler: "formSubmitHandler" }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn btn-primary backButton"], ["label", "Back"], ["pButton", ""]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.activeIndexChangeHandler((_co.activeIndex - 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Back"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeIndex; var currVal_1 = _co.items; var currVal_2 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.currentlyActiveForm; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.activeIndex <= 0); _ck(_v, 8, 0, currVal_4); }); }
function View_CreateTicketComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "padding-left: 40%; padding-top: 30%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p-progressSpinner", [], null, null, null, i7.View_ProgressSpinner_0, i7.RenderType_ProgressSpinner)), i1.ɵdid(2, 49152, null, 0, i8.ProgressSpinner, [], null, null)], null, null); }
export function View_CreateTicketComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "heirarchy"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTicketComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTicketComponent_2)), i1.ɵdid(4, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentlyActiveForm; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.isLoadComplete; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_CreateTicketComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-create-ticket", [], null, null, null, View_CreateTicketComponent_0, RenderType_CreateTicketComponent)), i1.ɵdid(1, 114688, null, 0, i10.CreateTicketComponent, [i11.CookieService, i12.Router, i12.ActivatedRoute, i13.MessageService, i14.TicketService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateTicketComponentNgFactory = i1.ɵccf("app-create-ticket", i10.CreateTicketComponent, View_CreateTicketComponent_Host_0, {}, {}, []);
export { CreateTicketComponentNgFactory as CreateTicketComponentNgFactory };
